.footer-icon-button {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    margin: 0 10px;
    transition: color 0.3s ease;
}

.bg-custom-footer {
    background-color: #555555;
    padding: 20px;
}

.form-container {
    width: 100%;
    max-width: 300px;
    background: linear-gradient(#212121, #212121) padding-box, linear-gradient(145deg, transparent 35%, var(--app-color), #40c9ff) border-box;
    border: 2px solid transparent;
    padding: 16px 24px;
    font-size: 14px;
    font-family: inherit;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 15px;
    box-sizing: border-box;
    border-radius: 16px;
    background-size: 200% 100%;
    animation: gradient 5s ease infinite;
    align-items: flex-end;
    margin-left: auto;
    margin-right: auto;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.form-container button:active {
    scale: 0.95;
}

.form-container .form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-container .form-group {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.form-container .form-group label {
    display: block;
    margin-bottom: 5px;
    color: var(--app-color);
    font-weight: 600;
    font-size: 12px;
}

.form-container .form-group input,
.form-container .form-group textarea {
    width: 100%;
    padding: 10px 14px;
    border-radius: 8px;
    color: #fff;
    font-family: inherit;
    background-color: transparent;
    border: 1px solid #414141;
}

.form-container .form-group textarea {
    resize: none;
    height: 80px;
    border: 1px solid #414141;
    background-color: transparent;
    font-family: inherit;
}

.form-container .form-group input::placeholder {
    opacity: 0.5;
}

.form-container .form-group input:focus,
.form-container .form-group textarea:focus {
    outline: none;
    border-color:var(--app-color);
}

.form-container .form-submit-btn {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-self: flex-start;
    font-family: inherit;
    color: #fff;
    font-weight: 600;
    width: 40%;
    background: #000;
    border: 1px solid #414141;
    padding: 10px 14px;
    font-size: inherit;
    gap: 8px;
    margin-top: 8px;
    cursor: pointer;
    border-radius: 6px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.form-container .form-submit-btn:hover {
    background-color:var(--app-color);
    border-color:var(--app-color);
    color: #000;
}

.footer-icon-button:hover {
    color: var(--app-color);
}

.footer-icon-button.fb:hover {
    color: #3b5998;
}

.footer-icon-button.ig:hover {
    color: #e1306c;
}

.footer-icon-button.tw:hover {
    color: #1da1f2;
}

.footer-icon-button.li:hover {
    color: #0077b5;
}

.footer-icon-button.yt:hover {
    color: #ff0000;
}

.mt-6 {
    margin-top: 1.5rem;
}

@media (max-width: 480px) {
    .form-container {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 12px;
    }

    .form-container .form-submit-btn {
        width: 100%;
        height:100%;
    }
    
    .form-container .form-group label {
        font-size: 10px;
    }

    .form-container .form-group input,
    .form-container .form-group textarea {
        font-size: 12px;
        padding: 8px;
    }
}
