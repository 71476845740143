body {
    background-color: white;
    color: black;
}

body.dark-mode {
    background-color: black;
    color: white;
}

.about-heading {
    color: gray;
    border-bottom: 2px solid var(--app-color);
    text-transform: uppercase;
}

.about-heading2 {
    /* color: gray; */
    /* border-bottom: 2px solid var(--app-color); */
    /* text-transform: uppercase; */
    font-size: 1.2em; /* Adjust size as needed */
    font-weight: bold;
    
}

.emoji {
    font-size: 1.5em; /* Adjust size as needed */
    vertical-align: middle; /* Aligns emoji with text */
    margin-right: 0.2em; /* Adds spacing after emoji */
  }
  

.about-title {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 1.875rem;
    color: var(--app-color);
}

.highlighted-text {
    color: var(--app-color);
}

.subheading, 
.about-paragraph, 
.about-list li {
    color: black; 
}

.about-section, .about-content, .image-container {
    background-color: inherit;
}

.about-list {
    list-style: disc inside;
    margin-bottom: 1rem;
}

.contact-button {
    padding: 0.5rem;
    width: 200px;
    display: inline-block;
    text-align: center;
}

.contact-button .text_button {
    color: inherit;
}

.image-container {
    text-align: center;
}

.about-image {
    border-radius: 0.5rem;
    max-width: 100%;
    height: auto;
    margin: 0;
    border: none;
}

.image img {
    margin: 0;
    border: none;
}

@media (max-width: 640px) {
    section {
        padding: 20px;
    }

    .image img {
        width: 100%;
        height: auto;
        max-width: 300px;
    }
}

@media (min-width: 640px) {
    .image img {
        max-width: 100%;
    }
}
