.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 1rem;
    transition: box-shadow 0.3s ease;
    z-index: 20;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



.nav-links {
    display: flex;
    gap: 30px;
    
}

.nav-link {
    color: #4A4A4A;
    text-decoration: none;
    position: relative;
    transition: color 0.3s ease;
    font-family: 'Arial', sans-serif;
    font-size: 15px;
}

.nav-link::after {
    content: '';
    display: block;
    height: 2px;
    width: 0;
    background: var(--app-color); 
    transition: width 0.3s ease;
    position: absolute;
    left: 0;
    bottom: -5px;
}

.nav-link:hover {
    color: #1F2937;
}

.nav-link:hover::after {
    width: 100%; 
}

.nav-link.active {
    color: #1F2937; 
}

.nav-link.active::after {
    width: 100%; 
    background: var(--app-color); 
}

.nav-link.active:hover::after {
    width: 100%; 
    background: var(--app-color); 
}



.button {
    position: relative;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    padding-block: 0.5rem;
    padding-inline: 1.25rem;
    background-color:var(--app-color); 
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    gap: 5px;
    font-weight: bold;
    border: 3px solid #ffffff4d;
    outline: none;
    overflow: hidden;
    font-size: 15px;
    cursor: pointer;
}

.icon {
    width: 24px;
    height: 24px;
    transition: all 0.3s ease-in-out;
}

.button:hover {
    transform: scale(1.05);
    border-color: #fff9;
}

.button:hover .icon {
    transform: translate(4px);
}

.button:hover::before {
    animation: shine 1.5s ease-out infinite;
}

.button::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(
        120deg,
        rgba(255, 255, 255, 0) 30%,
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0) 70%
    );
    top: 0;
    left: -100px;
    opacity: 0.6;
}

@keyframes shine {
    0% {
        left: -100px;
    }
    60% {
        left: 100%;
    }
    to {
        left: 100%;
    }
}

.hamburger {
    display: none; 
    font-size: 24px;
    cursor: pointer;
    position: absolute; 
    right: 30px;
}

.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 50;
}

.sidebar {
    position: fixed;
    top: 0;
    right: 0;
    width: 75%;
    max-width: 300px;
    height: 100%;
    background: white;
    z-index: 51;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    transform: translateX(0);
    display: flex;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
}



.sidebar.active {
    display: block; 
    transform: translateX(0); 
}

.sidebar-links {
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center; /* Center text alignment */
}


.sidebar-link {
    color: #4A4A4A;
    text-decoration: none;
    font-size: 18px;
    transition: color 0.3s ease;
}

.sidebar-link:hover {
    color: #7df9ff ; 
}

.sidebar-apply-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: var(--app-color);
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
}

.sidebar-apply-button:hover {
    background-color: #007bff; /* Lighter shade on hover */
}



@media (max-width: 1250px) {
    .navbar {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 8px;
    }

    .nav-links {
        display: none; 
    }

    .hamburger {
        display: block; 
    }

    .button {
        display: none; 
    }

    .sidebar {
        display: block; 
    }
}
