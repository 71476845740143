
body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .divider {
    width: 80%;
    margin: 10px auto;
    border-top: 1px solid #ccc;
  }
  
  /* Responsive layout for SectionWidget and general containers */
  .section-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
  }
  
  .section-widget {
    flex: 1 1 100%; /* Full width on smaller screens */
    margin: 10px 0;
  }
  
  /* Responsive adjustments */
  @media (min-width: 768px) {
    .divider {
      width: 60%;
    }
  
    .section-widget {
      flex: 1 1 48%; /* Two columns on medium screens */
    }
  }
  
  @media (min-width: 1024px) {
    .divider {
      width: 50%;
    }
  
    .section-widget {
      flex: 1 1 30%; /* Three columns on larger screens */
    }
  }
  
  /* Add padding and margin to sections */
  [data-aos='fade-up'] {
    padding: 20px 0px;
  }
  