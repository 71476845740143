.grid-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    font-family: Arial, sans-serif;
/* background-color: rgb(246, 246, 246); */
}

.grid-children {
    display: grid;
    grid-template-columns: repeat(4, minmax(200px, 1fr)); /* Default: 3 columns, spreading items */
    justify-content: space-evenly; /* Spread items evenly horizontally */
    gap: 40px; /* Adjust gap for more spacing */
}

/* Adjust for medium-sized screens (e.g., tablets) */
@media (max-width: 1024px) {
    .grid-children {
        grid-template-columns: repeat(2, minmax(150px, 1fr)); /* 2 columns for smaller screens */
        gap: 30px; /* Slightly smaller gap */
    }
}

.grid-circle {
    width: 200px;
    height: 200px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(42, 238, 98, 0.6);
    text-align: center;
    padding: 10px;
}
.grid-circle:hover {
    transform: scale(1.05);
  }
  
.grid-circle h1 {
    font-size: 16px;
    margin: 0;
}

.grid-circle h2 {
    font-size: 14px;
    margin: 5px 0;
    color: gray;
}

.emoji {
    font-size: 43px;
    margin-top: 5px;
}
