/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Gilroy';
  src: url('/public/fonts/gilryoy.woff2') format('woff2');
  font-display: swap; 
}

*{
  font-family: 'Gilroy', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, html{
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.transition {
  transition: all 0.3s ease;
}

h1, p {
  margin: 0;
}

header {
  height: 80px; 
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between; 
  position: fixed; 
  top: 0; 
  left: 0; 
  right: 0;
  z-index: 10; 
}

header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

header img {
  height: 100px; 
}

.slogan {
  font-size: 1.5rem; 
  color: #333; 
  margin-left: 16px; 
}

@media (max-width: 768px) {
  header img {
    height: 80px; 
  }

  .slogan {
    font-size: 1.25rem; 
  }

  .flex {
    gap: 0.5rem; 
  }

  .flex button {
    width: 24px; 
    height: 24px; 
  }

  .flex svg {
    width: 24px; 
    height: 24px; 
  }
}

header .flex {
  gap: 1rem; 
}

@media (max-width: 768px) {
  header {
    flex-direction: column;
    align-items: center;
    height: auto; 
    padding: 16px; 
  }

  header .flex {
    flex-direction: row; 
    align-items: center;
    gap: 1rem; 
  }
}
